import { Router } from "@angular/router";
import { AuthenticationResult, BrowserAuthOptions, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { Client, GraphRequest } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";

export interface MicrosoftGraphConfig { clientId: string, authority: string, scopes: string[] }

export class MicrosoftGraph {
    private _clientId: string;
    private _authority: string;
    private _scopes: string[];
    constructor({ clientId, authority, scopes }: MicrosoftGraphConfig) {
        this._clientId = clientId;
        this._authority = authority;
        this._scopes = scopes;
    }

    /**
     * Represents the client used for making requests to the API.
     * Remember to always do a "null" check before using the client.
     * @example
     * Example usage:
     * graph.client.api('/me')
     *   .get()
     *   .then((response) => {
     *     console.log(response);
     * });
     * 
     *
     */
    client: Client | null = null;



    async initiateClient(): Promise<{ success: boolean, message: string }> {
        return new Promise(async (resolve, reject) => {
            try {
                const authOptions: BrowserAuthOptions = {
                    clientId: this._clientId,
                    authority: this._authority,
                    redirectUri: window.location.href
                }
                let pca: PublicClientApplication | null = null;
                let authResult: AuthenticationResult | null = null;
                let authProvider: AuthCodeMSALBrowserAuthenticationProvider | null = null;

                try {
                    pca = new PublicClientApplication({ auth: authOptions });
                } catch (error) {
                    console.log(error);
                    throw new Error(String(error));
                }

                if (pca === null) throw new Error('Could not create public client');

                try {
                    await pca.initialize();
                } catch (error) {
                    console.log(error);
                    throw new Error(String(error));
                }

                try {
                    authResult = await pca.acquireTokenSilent({ scopes: this._scopes });

                } catch (error) {
                    console.error(error);
                    
                    console.log('Failed to get token silently, trying to get token interactively');
                    
                    try {
                        authResult = await pca.acquireTokenPopup({ scopes: this._scopes });
                    } catch (error) {
                        console.log(error);
                        throw new Error(String(error));
                    }
                }

                if (authResult === null || !authResult.account) throw new Error('Could not acquire token');

                pca.setActiveAccount(authResult.account);

                const authCodeOptions = {
                    account: authResult.account,
                    interactionType: InteractionType.Popup,
                    scopes: this._scopes,
                }
                try {
                    authProvider = new AuthCodeMSALBrowserAuthenticationProvider(pca, authCodeOptions);
                } catch (error) {
                    console.log(error);
                    throw new Error(String(error));
                }

                if (authProvider === null) throw new Error('Could not create auth provider');

                try {
                    this.client = Client.initWithMiddleware({ authProvider: authProvider });
                    resolve({ success: true, message: 'Client initiated successfully' });
                } catch (error) {
                    console.log(error);
                    throw new Error(String(error));
                }
            } catch (error) {
                reject({ success: false, message: error });
            }

        })
    }
}
